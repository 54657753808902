<template>
  <v-container id="data-tables-view" fluid tag="section">
    <v-col v-if="canDeleteCsvErrors" class="text-right">
      <app-btn @click="dialogDelete = true">
        <v-icon>mdi-view-grid-plus</v-icon> Clear Logs
      </app-btn>
    </v-col>
    <material-card
      icon="mdi-clipboard-list"
      icon-small
      color="primary"
      title="Csv Errors"
    >
      <v-card-text>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="ml-auto"
          hide-details
          label="Search records"
          single-line
          style="max-width: 250px"
        />

        <v-divider class="mt-3" />

        <v-data-table
          :headers="headers"
          :items="csvErrors"
          :options.sync="options"
          :search.sync="search"
          :server-items-length="totalCsvErrors"
          :loading="loading"
          :footer-props="{
            showFirstLastPage: true,
            'items-per-page-options': [5, 10, 15],
          }"
        >
          <template v-slot:top>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">
                  Are you sure you want to delete this item?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogDelete = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.errorType`]="{ item }">
            <div>{{ getErrorTypeName(item) }}</div>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <div>{{ dateFormat(item.createdAt) }}</div>
          </template>
        </v-data-table>
      </v-card-text>
    </material-card>
  </v-container>
</template>
<script>
import OrdersDataService from "../services/OrderDataService";
import moment from "moment";

export default {
  name: "CsvErrors",
  data: () => ({
    permissions: localStorage.getItem("permissions"),
    dialogDelete: false,
    options: {},
    totalCsvErrors: 0,
    loading: false,
    headers: [
      {
        text: "CsvLine",
        value: "csvLine",
      },
      {
        text: "Error Type",
        value: "errorType",
      },
      {
        text: "Csv name",
        value: "csvName",
      },
      { text: "Created at", value: "createdAt" },
    ],
    csvErrors: [],
    errorTypes: [
      {
        id: 1,
        text: "Missing Field",
      },
      {
        id: 2,
        text: "Bad Data",
      },
      {
        id: 3,
        text: "Product Id Not Valid",
      },
      {
        id: 4,
        text: "Prd Id doesn't match",
      },
      {
        id: 5,
        text: "Duplicate record",
      },
    ],
    awaitingSearch: false,
    search: "",
  }),

  computed: {
    canDeleteCsvErrors() {
      return this.permissions.includes("Permissions.CsvErrors.Delete");
    },
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi(this.search);
      },
      deep: true,
    },
    search: {
      handler() {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.getDataFromApi(this.search);
            this.awaitingSearch = false;
          }, 2500); // 1 sec delay
        }
        this.awaitingSearch = true;
      },
    },
  },
  created() {
    // this.getDataFromApi();
  },

  methods: {
    getDataFromApi(search) {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      var data = {
        pageNumber: page,
        pageSize: itemsPerPage,
        Search: search,
      };

      let call;
      if (this.$route.query.csvId) {
        call = this.$http.post(
          `Shifts/${this.$route.query.csvId}/Errors/Search`,
          data
        );
      } else {
        call = this.$http.post("Shifts/Errors/Search", data);
      }

      call
        .then((response) => {
          if (response.data.success) {
            this.csvErrors = response.data.data.items;
            this.totalCsvErrors = response.data.data.totalCount;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    deleteItemConfirm() {
      OrdersDataService.removeCsvErrors().then((response) => {
        if (response.status === 200) {
          this.$toast.success(
            "Csv error logs removed successfully.",
            "Success",
            {
              position: "topRight",
            }
          );
          this.getDataFromApi(this.search);
        }
      });

      this.dialogDelete = false;
    },
    dateFormat(date) {
      if (date) {
        return moment.utc(date).local().format("h:mm:ss a DD.MM.YYYY");
      } else {
        return "";
      }
    },
    getErrorTypeName(item) {
      let errorType = this.errorTypes.find((x) => x.id == item.errorType);
      if (errorType) {
        return errorType.text;
      }
      return "";
    },
  },
};
</script>
