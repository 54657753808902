var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"data-tables-view","fluid":"","tag":"section"}},[(_vm.canDeleteCsvErrors)?_c('v-col',{staticClass:"text-right"},[_c('app-btn',{on:{"click":function($event){_vm.dialogDelete = true}}},[_c('v-icon',[_vm._v("mdi-view-grid-plus")]),_vm._v(" Clear Logs ")],1)],1):_vm._e(),_c('material-card',{attrs:{"icon":"mdi-clipboard-list","icon-small":"","color":"primary","title":"Csv Errors"}},[_c('v-card-text',[_c('v-text-field',{staticClass:"ml-auto",staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","hide-details":"","label":"Search records","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-3"}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.csvErrors,"options":_vm.options,"search":_vm.search,"server-items-length":_vm.totalCsvErrors,"loading":_vm.loading,"footer-props":{
          showFirstLastPage: true,
          'items-per-page-options': [5, 10, 15],
        }},on:{"update:options":function($event){_vm.options=$event},"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Are you sure you want to delete this item? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v(" OK ")]),_c('v-spacer')],1)],1)],1)]},proxy:true},{key:"item.errorType",fn:function(ref){
        var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.getErrorTypeName(item)))])]}},{key:"item.createdAt",fn:function(ref){
        var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.dateFormat(item.createdAt)))])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }